$(document).foundation();

$(document).ready(function() {
	// utils
	initVerticalCenter();
	inlineSVGs('.footer__social img');
	// initAnimateInView();

	// global
	initSliders();
	//nitBookingBar();
	initForms();
	initHeader();
	initToggleInView();
	initFooter();
	initOffCanvasNav();

	// template specific
	resizeHomepage();
	animateHomepage();
	$(window).on('resize', resizeHomepage);
	initOffers();
	initAccordions();
	initDetailCTA();
	initGallery();
	showHideBlogInfo();
	initBlogs();
	initLoadMore();
	initPopups();
	initLandingGrid();
	initFeaturedCarousel();
	initDateSelection();
	initBookTable();
	// initFeaturedCarouselBackup();
});



function initBookTable() {
	$('a[href="#bookTable"]').on('click', function (event) {
		event.preventDefault();
		$('#booking').foundation('open');
		$('#booking__picker-select option[value="book-table"]').prop("selected", true);
		$('#booking__picker-select').trigger('change').selectric('refresh');
		return false;
	});
}

document.addEventListener("DOMContentLoaded", function() {
    // Check if the URL contains a fragment identifier
    if (window.location.hash)
	{
        // Get the fragment identifier (excluding the '#')
        var fragment = window.location.hash.substring(1);

        // Check if the fragment identifier is equal to "bookTable"
        if (fragment === "bookTable") {

			// Target the HIDDEN INPUT in _Booking.html
			var buttonToClick = document.querySelector('a[href="#bookTable"]');

			if (buttonToClick)
			{
				buttonToClick.click();
				$('#booking__picker-select option[value="book-table"]').prop("selected", true);
				$('#booking__picker-select').trigger('change').selectric('refresh');
			}
        }
    }
});

function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		if ( $(this).hasClass('in-view') ) {
			return;
		}

		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;

			$(elementToAnimate).addClass('in-view'); // log that this element has been seen


			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
			}, 300);


			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;

	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}



function initLoadMore() {

	initGalleryLoadMore();
	initBlogLoadMore();
}

function initGalleryLoadMore() {
	$('#gallery-load-more').click(function() {
		$.ajax({
			url: $(this).data("url"),
			success : function(html){
				if(html){
					$('#gallery-load-more').remove();
					$(".gallery").append(html);
					initGalleryLoadMore();
					initGallery();
				}
			}
		});
		$(this).hide();
	})
}

function initBlogLoadMore() {
	$('#blog-load-more').click(function() {
		var url = $(this).data("url");
		$.ajax({
			url: url,
			success : function(html){
				if(html){
					$('#blog-load-more').remove();
					$(".blog").append(html);
					initBlogLoadMore();
				}
			}
		});
		$(this).hide();
	})
}


function initVerticalCenter() {
	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});
}


if(!$(".detail-cta .right").length){
	$('.cta-half').addClass('single');
}


// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(selectorString) {
	$(selectorString).each(function(){
		var $img = $(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');

		$.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = $(data).find('svg');

			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
			if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
				$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
			}

			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});
}



// For some reason Foundation's Equalizer wasn't working, so do it the old-fashioned way...
function resizeHomepage() {
	var tallest = 0;
	var $equalizeThese = $('.home-panels__column .home-panel--text, .home-panels__column .home-panel--feature');

	$equalizeThese.each(function () {
		if ($(this).outerHeight() > tallest) {
			tallest = $(this).outerHeight();
		}
	});

	$equalizeThese.css('height', tallest + 'px');
	setTimeout(function () {
		$(window).trigger('resize');
	}, 100);
}

function animateHomepage() {
	// Pan the top middle image
	/*setTimeout(function () {
		$('.home-panel--feature-panner').addClass('animated');
	}, 200);*/
	if ($('.home-panels').length > 0) {
		Foundation.onImagesLoaded($('.home-panels__pre-loader'), function () {
			$('.home-panel--feature-panner').addClass('animated');
		});
	}
}



/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
	if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

	var viewportWidth, viewportHeight;
	if (scaleToParent) {
		viewportWidth = $(element).parent().innerWidth();
		viewportHeight = $(element).parent().innerHeight();
	} else {
		viewportWidth = $(window).innerWidth();
		viewportHeight = $(window).innerHeight();
	}

	var $element = $(element);

	var elementWidth = $element.attr('width');
	var elementHeight = $element.attr('height');

	// Aspect ratio = height / width
	var viewportRatio = viewportHeight / viewportWidth;
	var videoRatio = elementHeight / elementWidth;

	//console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


	var newWidth = (elementWidth / elementHeight) * viewportHeight
	var newHeight = (elementHeight / elementWidth) * viewportWidth;
	var newTop = 0;

	if (viewportRatio > videoRatio) {
		// set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
		//console.log('crop left and right');
		$element.css('height', viewportHeight);
		$element.css('width', newWidth);
		$element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
		$element.css('top', '0');
	} else if (viewportRatio < videoRatio) {
		// set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
		//console.log('crop top and bottom');
		$element.css('width', viewportWidth);
		$element.css('height', newHeight);
		$element.css('left', '0');
		$element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
	} else {
		// set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
		//console.log('no crop');
		$element.css('height', viewportHeight);
		$element.css('width', viewportWidth);
		$element.css('left', '0');
		$element.css('top', '0');
	}
}


function initSliders() {

	$('.slider').slick({
		autoplay: true,
		fade: true,
		arrows: true,
		pauseOnHover: false
	});

	if ( $('.large-slider__inner iframe').length > 0 ) {
		return
	} else {
		$('.large-slider__inner').reformSlider({
			speed: 3500,
			animationSpeed: 750,
			arrows: true,
			pagination: false,
			paginationArrows: false
		});
	}
}




function initOffers() {
	resizeOffers();

	var $_filterButtons = $('.filters__inner button');
	var $_filterItems = $('.offers__item');

	$_filterButtons.on('click', function() {
		$('.filters__inner button.on').removeClass('on');
		$(this).addClass('on');

        // Clear the "All" button
        if (!$(this).hasClass('all')) {
        	$('.filters__inner button.all').removeClass('on');
        } else {
            // All button has been clicked, so show all buttons and return
            $('.filters__inner button').removeClass('on');
            $('.filters__inner button.all').addClass('on');
            $_filterItems.fadeIn();
            setTimeout(resizeOffers, 500);
            if (window.location.href.toLowerCase().indexOf('offers') > -1) {
	            changeBookingBar('book-room'); // reset the booking bar
	        }
	        return;
	    }

	    var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
        	if ($(this).hasClass('on')) {
        		currentCats += '.' + $(this).data('category') + ', ';
        	}
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
        	$_filterItems.fadeIn();
        	setTimeout(resizeOffers, 500);
        	if (window.location.href.toLowerCase().indexOf('offers') > -1) {
	            changeBookingBar('book-room'); // reset the booking bar
	        }
	        return;
	    }

        // Hide or show the elements that match the class
        $_filterItems.each(function() {
        	if ($(this).is(currentCats)) {
        		$(this).fadeIn();
        	} else {
        		$(this).fadeOut();
        	}
        });
        setTimeout(resizeOffers, 500);

        // Change the booking bar to match the filter chosen
        if (window.location.href.toLowerCase().indexOf('offers') > -1) {
        	switch (currentCats) {
        		case '.dining':
        		changeBookingBar('book-table');
        		break;
        		case '.spa':
        		changeBookingBar('book-treatment');
        		break;
        		case '.wedding-offers':
        		changeBookingBar('book-wedding');
        		break;
        		default:
        		changeBookingBar('book-room');
        		break;
        	};
        }
    });

    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
        	return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

        while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

        if (urlParams['category']) {
        	$('.filters__inner button[data-category="' + urlParams['category'] + '"]').trigger('click');
        }


    // Trigger the correct filter buttons when the mobile filter dropdown is used
    $('.filters__mobile-selecter').on('change', function () {
    	$('.filters__inner button.on').removeClass('on');
    	$('.filters__inner button[data-category="' + $(this).val() + '"]').trigger('click');
    });
}


// Stretch orphaned offer items to the full page width
function resizeOffers() {
	// reset
	$('.undo-full-width').addClass('medium-6').removeClass('.undo-full-width');

	var narrowItemCount = 0;

	$('.offers__item').each(function () {
		if ($(this).is(':visible')) {
			if ($(this).hasClass('medium-6')) {
				narrowItemCount++;
			} else {
				if ( narrowItemCount % 2 ) {
					$(this).prev().removeClass('medium-6').addClass('undo-full-width');
				}
				narrowItemCount = 0;
			}
		}
	});
}

function initAccordions() {
	$('.accordion').accordiom();

	$('.contact__accordion').accordiom({
		showFirstItem: false
	});

	$('.room-detail__accordion').accordiom({
		showFirstItem: false
	});
	
}


function initHeader() {
	if ( $('body').hasClass('home') ){
		toggleHomepageHeader();
		$(window).on('resize', toggleHomepageHeader);
	}
}

function toggleHomepageHeader() {
	if ( window.scrollY > ($('.home-panel--logo').outerHeight() / 2) ) {
		$('.header').addClass('visible');
	} else {
		$('.header').removeClass('visible');
	}
}






function initForms() {
	$('.contact__form').validate();
}




$.fn.customSelect = function() {
	if ( $(this).length ) {
		$(this).find('select').attr('selectedIndex',-1).bind('change.customSelect', function() {
			var optionText = $(this).find('option:selected').text();
			$(this).siblings('label').text(optionText)
		});
	}
};


function initBookingBar() {


	$('.custom-select select').customSelect();



	var nowTemp = new Date();
	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

	var checkin = $('#check-in').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		updateDeparture();
	}).data('datepicker');


	$('#check-in').on('focus, click', function () {
		positionCalendar();
	});

	positionCalendar();
	//$(window).on('resize', positionCalendar);


	$('#nights').on('change', updateDeparture);



	// Calculate the departure date from the arrival date plus the number of nights
	function updateDeparture() {
		if ( $('#check-in').val() != '' ) {
			var arrival = new Date(checkin.date);
			var nights = $('#nights').val();

			var departure = new Date(arrival);
			departure.setDate(departure.getDate() + (nights*1));
			$('#check-out').val( departure.getDate() + '/' + (departure.getMonth() + 1*1) + '/' + departure.getFullYear() );
		}
	}




	$('.booking-bar-synxis form').on('submit', function () {
		if ( $('#check-in').val() === '' ) {
			// autopopulate arrival date if one was not selected

			var today = new Date();
			$('#check-in').val( today.getDate() + '/' + (today.getMonth() + 1*1) + '/' + today.getFullYear() );

			var nights = $('#nights').val();
			var departure = new Date(today);
			departure.setDate(departure.getDate() + (nights*1));
			$('#check-out').val( departure.getDate() + '/' + (departure.getMonth() + 1*1) + '/' + departure.getFullYear() );
		}
	});



	// Widget changing
	$('.booking-bar-selector select').on('change', function () {
		changeBookingBar($(this).val());
	});



	// Show the right widget depending on the current section
	if (window.location.href.indexOf('/eat-drink/') > -1) {
		changeBookingBar('book-table');
	} else if (window.location.href.indexOf('/spa-wellbeing/') > -1) {
		changeBookingBar('book-treatment');
	} else if (window.location.href.indexOf('/meetings-events/') > -1) {
		changeBookingBar('book-event');
	} else if (window.location.href.indexOf('/weddings/') > -1) {
		changeBookingBar('book-wedding');
	}
}



function changeBookingBar(bookingType) {
	// Default to rooms if the booking type is not valid
	if ($('.booking-bar-type[data-book="' + bookingType + '"]').length === 0) {
		bookingType = 'book-room';
	}

	$('.booking-bar-type').addClass('booking-bar-hidden');
	$('.booking-bar-type[data-book="' + bookingType + '"]').removeClass('booking-bar-hidden');
	$('.booking-bar-selector select').val(bookingType);
}


function positionCalendar() {
	try {
		var fieldPositon = document.getElementById('check-in').getBoundingClientRect();
		var style = document.createElement('style');
		style.type = 'text/css';
		style.innerHTML = '.datepicker.datepicker-dropdown.dropdown-menu { top: auto !important; bottom: ' + (89 - window.scrollY) + 'px !important; }';
		document.getElementsByTagName('head')[0].appendChild(style);
	} catch(e) {
		return;
	}
}



function initDetailCTA() {
	$('.detail-cta__slideout-close').on('click', function () {
		$('.detail-cta__slideout').removeClass('visible');
	});

	$('.detail-cta__secondary-link').on('click', function () {
		$('.detail-cta__slideout').addClass('visible');
		return false;
	});

	if ( $('.detail-cta__slideout').length > 0 ) {
		resizeCTASlideout();
		$(window).on('resize', resizeCTASlideout);
	}
}


// Scale the popout book direct message only if there is a features list to cover.
function resizeCTASlideout() {
	if ( $('.features').length > 0 ) {
		$('.detail-cta__slideout').css('width', $('.features').outerWidth() + 'px');
	} else {
		$('.detail-cta__slideout').addClass('no-features');
	}
}


function initToggleInView() {

	if ($('[data-toggle-in-view]').length === 0) {
        // nothing to toggle
        return;
    }

    $(window).on('scroll.toggle-in-view', function(e) {
    	var screenBottom = $(window).scrollTop() + window.innerHeight;

    	$('[data-toggle-in-view]').each(function(i, el) {
    		var elem = $(el);

    		if (elem.offset().top + 100 > screenBottom) {
                // element not in view yet
                return;
            }

            // toggle class
            elem.addClass(elem.data('toggle-in-view'));

            // remove attribute
            elem.removeAttr('data-toggle-in-view');
        });

    	if ($('[data-toggle-in-view]').length === 0) {
            // all elements visible, remove scroll event handler
            $(window).off('scroll.toggle-in-view');
        }
    });

    if ($('[data-toggle-in-view]').length > 0) {
	    // Trigger the first scroll
	    window.scrollTo(0, 1);
	}
}

function initGallery() {
	$('.gallery__item-cta a').on('click', function (e) {
		e.preventDefault();
        // Dynamically load the gallery slider into a reveal popup
        var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">X</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load($(this).attr('href'), function () {
        	$modal.removeClass('loading').find('.gallery-modal__slider').slick({
        		arrows: true,
        		dots: false,
        		fade: true
        	});
        });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
        	$modal.remove();
        });

        return false;
    });
}

function showHideBlogInfo() {
	$('.blog__item').mouseenter( function() {
		$(this).find('.blog__item-info').css("display", "block");
	});

	$('.blog__item').mouseleave( function() {
		$(this).find('.blog__item-info').css("display", "none");
	});
}


function initBlogs() {
	resizeBlog();

	var $_filterButtons = $('.blog-filters__inner button');
	var $_filterItems = $('.blog__item');

	$_filterButtons.on('click', function() {
		$('.blog-filters__inner button.on').removeClass('on');
		$(this).addClass('on');

        // Clear the "All" button
        if (!$(this).hasClass('all')) {
        	$('.blogfilters__inner button.all').removeClass('on');
        } else {
            // All button has been clicked, so show all buttons and return
            $('.blog-filters__inner button').removeClass('on');
            $('.blog-filters__inner button.all').addClass('on');
            $_filterItems.fadeIn();
            setTimeout(resizeBlog, 500);
            if (window.location.href.toLowerCase().indexOf('blogs') > -1) {
	            changeBookingBar('book-room'); // reset the booking bar
	        }
	        return;
	    }

	    var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
        	if ($(this).hasClass('on')) {
        		currentCats += '.' + $(this).data('category') + ', ';
        	}
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
        	$_filterItems.fadeIn();
        	setTimeout(resizeBlog, 500);
        	if (window.location.href.toLowerCase().indexOf('blogs') > -1) {
	            changeBookingBar('book-room'); // reset the booking bar
	        }
	        return;
	    }

        // Hide or show the elements that match the class
        $_filterItems.each(function() {
        	if ($(this).is(currentCats)) {
        		$(this).fadeIn();
        	} else {
        		$(this).fadeOut();
        	}
        });
        setTimeout(resizeBlog, 500);
    });

    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
        	return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

        while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

        if (urlParams['category']) {
        	$('.blog-filters__inner button[data-category="' + urlParams['category'] + '"]').trigger('click');
        }


    // Trigger the correct filter buttons when the mobile filter dropdown is used
    $('.blog-filters__mobile-selecter').on('change', function () {
    	$('.blog-filters__inner button.on').removeClass('on');
    	$('.blog-filters__inner button[data-category="' + $(this).val() + '"]').trigger('click');
    });
}


// Stretch orphaned offer items to the full page width
function resizeBlog() {
	// reset
	$('.undo-full-width').addClass('medium-6').removeClass('.undo-full-width');

	var narrowItemCount = 0;

	$('.blog__item').each(function () {
		if ($(this).is(':visible')) {
			if ($(this).hasClass('medium-6')) {
				narrowItemCount++;
			} else {
				if ( narrowItemCount % 2 ) {
					$(this).prev().removeClass('medium-6').addClass('undo-full-width');
				}
				narrowItemCount = 0;
			}
		}
	});
}

function initPopups() {
	var $popups = $('.popup-modal');

	if ($popups.length === 0) {
		return;
	}

	$popups.each(function () {
		var $popup = $(this);
		var id = $popup.data('id');
		var htmlId = $popup.attr('id'); // Get the HTML ID
		var showAfter = $popup.data('show-after');

//		var cookie = Cookies.get('popup-' + id);
//
//		if (cookie) {
//			// already dismissed this popup, so don't show it again
//			return;
//		}

		// Only apply setTimeout if the HTML ID is NOT 'navarino-bookpoint-modal'
		if (htmlId !== 'navarino-bookpoint-modal') {
			setTimeout(function () {
				$popup.foundation('open');
			}, showAfter * 1000);
		}

		$popup.one('closed.zf.reveal', function () {
			Cookies.set('popup-' + id, 'true', { expires: 30 });
		});
	});
}



function initLandingGrid() {
	if ( $('.landing-grid').length > 0 ) {
		setLandingGridContentHeight();
		$(window).on('resize', setLandingGridContentHeight);

		$('.landing-grid__content').css('height', 0);

		$('.landing-grid__item').on('mouseenter mouseleave touchend', function (e) {
			if ( $('html').hasClass('whatinput-types-touch') ) {
				// Is touchscreen
				$('.landing-grid__item.on').removeClass('on');
				$('.landing-grid__content').css('height', 0);

				$(this).toggleClass('on');

				if ( $(this).hasClass('on') ) {
					$(this).find('.landing-grid__content').css('height', $(this).find('.landing-grid__content').data('content-height') + 'px' );
				} else {
					$(this).find('.landing-grid__content').css('height', 0);
				}
			} else {
				// Is mouse
				$(this).toggleClass('on');

				if ( $(this).hasClass('on') ) {
					$(this).find('.landing-grid__content').css('height', $(this).find('.landing-grid__content').data('content-height') + 'px' );
				} else {
					$(this).find('.landing-grid__content').css('height', 0);
				}
			}
		});
	}
}

function setLandingGridContentHeight() {
	$('.landing-grid__content').each(function () {
		$(this).css('overflow', 'auto');
		$(this).data('content-height', $(this).find('.landing-grid__content-inner').outerHeight());
		$(this).css('overflow', 'hidden');
	});
}


function initFeaturedCarousel() {
	$('.featured-carousel__inner').each(function () {

		if ( $(this).find('.featured-carousel__item').length > 3 ) {
			$(this).addClass('has-arrows');
		}

		var isCenterMode = false;
		if ( $(this).find('.featured-carousel__item').length > 2 ) {
			isCenterMode = true;
		}

		$(".featured-carousel__inner").not('.slick-initialized').slick({
			arrows: true,
			dots: false,
			slidesToShow: 3,
			infinite: true,
			centerMode: isCenterMode,
			// Autoplay is hardcoded into the Feature Carousel HTML via data-slick - strange jQuery bug.
			// autoplay: true,
			// autoplaySpeed: 2000,
			centerPadding: '0px',
			responsive: [
				{
					breakpoint: 840,
					settings: {
						slidesToShow: 2,
						centerMode: false
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	});


	$(window).on('resize', alignSliderArrows);
	Foundation.onImagesLoaded($('.featured-carousel img'), alignSliderArrows);

	function alignSliderArrows() {
		var halfImgHeight = $('.featured-carousel__primary-image').first().outerHeight() / 2;
		$('.featured-carousel .slick-arrow').css('top', halfImgHeight + 'px');
	}
}

// function initFeaturedCarouselBackup() {
// 	$('.featured-carousel__inner').each(function () {

// 		if ( $(this).find('.featured-carousel__item').length > 3 ) {
// 			$(this).addClass('has-arrows');
// 		}

// 		var isCenterMode = false;
// 		if ( $(this).find('.featured-carousel__item').length > 2 ) {
// 			isCenterMode = true;
// 		}

// 		$(this).slick({
// 			arrows: true,
// 			dots: false,
// 			slidesToShow: 3,
// 			infinite: true,
// 			centerMode: isCenterMode,
// 			centerPadding: '0px',
// 			responsive: [
// 				{
// 					breakpoint: 840,
// 					settings: {
// 						slidesToShow: 2,
// 						centerMode: false
// 					}
// 				},
// 				{
// 					breakpoint: 640,
// 					settings: {
// 						slidesToShow: 1
// 					}
// 				}
// 			]
// 		});
// 	});


// 	$(window).on('resize', alignSliderArrows);
// 	Foundation.onImagesLoaded($('.featured-carousel img'), alignSliderArrows);

// 	function alignSliderArrows() {
// 		var halfImgHeight = $('.featured-carousel__primary-image').first().outerHeight() / 2;
// 		$('.featured-carousel .slick-arrow').css('top', halfImgHeight + 'px');
// 	}
// }




function initDateSelection() {
	//$('#booking').foundation('open');

	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];

	var bookingContainer = $('.booking__tabs-content-room form');

	var nowTemp = new Date();
	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: nowTemp,
		pick: function (e) {
			// User-facing date fields
			$('.check-in-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-in-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
		}
	});

	var checkout = $checkOutField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: tomorrow,
		pick: function (e) {
			// User-facing date fields
			$('.check-out-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-out-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
		}
	});


	$('.booking select').selectric();

	// Open table tab on Eat & Drink section pages
	if ( window.location.href.indexOf('eat-drink') > -1 ) {
		$('.booking select').val('book-table');
		$('.tabs-panel').removeClass('is-active');
		$('#book-table').addClass('is-active');
		$('.booking select').selectric('init');
	}

	$('.booking__tabs-content-room-why-book').accordiom({
		showFirstItem: false
	});


	$('#booking__picker-select').on('change', function () {
		$('.tabs-panel').removeClass('is-active');
		$('#' + $(this).val()).addClass('is-active');
	});



	$('#booking').on('open.zf.reveal', function () {
		// Only load 3rd party iframes when the booking panel is opened
		$('#booking iframe').each(function () {
			if (typeof $(this).data('src') != 'undefined') {
				$(this).attr('src', $(this).data('src'));
			}
		});
	});
}


function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


function initFooter () {
	var logoCarousel = $('.footer__logos-carousel')
	logoCarousel.slick({
		arrows: true,
		dots: false,
		slidesToShow: 5,
		centerMode: true,
		centerPadding: '0px',
		responsive: [
			{
				breakpoint: 840,
				settings: {
					slidesToShow: 3,
					centerMode: false
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});

}

function initOffCanvasNav() {

	// Automatically add the "Overview" links to the landing page
		$('.off-canvas__nav > ul > li').each(function () {

		/* if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		} */
	});


	$('.off-canvas__nav > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item closes
				$('.off-canvas__nav > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
			} else {
				// close any other open items
				$('.off-canvas__nav > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();

				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}
